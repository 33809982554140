<template>
  <cks-page-wrap v-loading="loading" style="padding-bottom: 100px;">
    <cks-edit-form
      @updatePosi="updatePosi"
      :isPosi="true"
      title="冷库招商编辑"
      @save="save"
      ref="formRef"
      label-position="top"
      :rules="rules"
      :model="form"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="招商名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="id">
          <el-form-item label="创建日期" prop="createdTime">
            <el-input disabled v-model="form.createdTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="id">
          <el-form-item label="更新日期" prop="updatedTime">
            <el-input disabled v-model="form.updatedTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="标题图片(1张, 建议图片尺寸520px * 310px,不应超过1M大小)" prop="titlePic">
            <cks-upload v-model="form.titlePic" @updateUrl="updateUrl" :fileUrl="form.titlePic" :limit="1"></cks-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="关联图片(1~6张, 建议图片尺寸1180px * 700px, 不应超过1M大小)" prop="linkedPic">
            <cks-upload v-model="form.linkedPic" @updateUrl="updateUrl2" :fileUrl="form.linkedPic" :limit="6"></cks-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="关联视频(建议视频格式.mp4, 不应超过300M大小)" prop="linkedVideo">
            <!-- <el-input v-model="form.linkedVideo"></el-input> -->
            <cks-upload-video v-model="form.linkedVideo" @deleteUrl="deleteUrl" @updateUrl="updateUrl3" :fileUrl="form.linkedVideo" :limit="1"></cks-upload-video>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="园区手册(支持格式.png, .jpg, .gif, .pdf, .xlsx, .xls, .docx, 不应超过10M大小)" prop="handBook">
            <el-upload
              v-model:file-list="fileList"
              action="action"
              :http-request="uploadFile"
              :show-file-list="true"
              :on-remove="handleRemove"
              :before-upload="beforeUpload"
              multiple
              :class="{'active-img-list': fileList.length >= 1}"
              :limit="1"
            >
              <el-button type="primary">上传文件</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="briefText">
            <template #label>
              <span style="color: red;">*</span> 园区概况
            </template>
            <div v-if="temp" class="posi"></div>
            <quill-editor ref="quillEditorRef" v-model="form.briefText" :options="editorOption"></quill-editor>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="城市选择" prop="provinceCityArea">
            <el-cascader :props="propsModle" v-model="form.provinceCityArea" :options="regionData" @change="handleChange" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="详细地址" prop="address">
            <el-select
              clearable
              :disabled="!form.provinceCityArea.length"
              v-model="form.address"
              filterable
              remote
              reserve-keyword
              placeholder="请输入详细地址"
              :remote-method="remoteMethod"
              @change="handleSelect"
              value-key="name"
              @blur="blurSelect"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="经度坐标" prop="coordinateX">
            <el-input disabled v-model="form.coordinateX"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="维度坐标" prop="coordinateY">
            <el-input disabled v-model="form.coordinateY"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </cks-edit-form>
  </cks-page-wrap>
</template>

<script>
import {
  toRefs, reactive, onMounted, shallowRef, nextTick,
} from 'vue';
import {
  showSuccess, getRequireRule, showError, showErrorMsg,
} from '@/utils';
import apis from '@/request/apis';
import { useRouter } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { regionData } from 'element-china-area-data';
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  components: {
    QuillEditor,
  },
  props: ['id'],
  setup(props) {
    const map = shallowRef(null);
    const router = useRouter();
    const data = reactive({
      fileList: [],
      propsModle: {
        value: 'label',
        label: 'label',
      },
      regionData,
      formRef: null,
      form: {
        newsType: '1',
        provinceCityArea: [],
      },
      rules: {
        name: [getRequireRule('招商名称')],
        newsType: [getRequireRule('新闻类型')],
        provinceCityArea: [getRequireRule('城市')],
        address: [getRequireRule('详细地址')],
        titlePic: [getRequireRule('标题图片')],
        linkedPic: [getRequireRule('关联图片')],
        // briefText: [getRequireRule('园区概况')],
        // linkedVideo: [getRequireRule('关联视频')],
      },
      options: [],
      loading: false,
      temp: false,
      quillEditorRef: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
            ],
            [
              { color: [] },
              { background: [] },
            ],
            // ['image'],
          ],
        },
      },
    });

    async function save(done) {
      try { await data.formRef.validate(); } catch (e) { return done(false); }
      if (data.quillEditorRef.getHTML() === '<p><br></p>') {
        done(false);
        showError(new Error('园区概况不能为空!'));
        return;
      }
      try {
        await apis.coldStorage.save({
          ...data.form,
          briefText: data.quillEditorRef.getHTML(),
        });
        showSuccess('保存成功');
        done();
        router.push({
          name: 'coldStorage',
        });
      } catch (e) {
        done(e);
      }
    }

    async function loadDetail() {
      data.loading = true;
      try {
        const res = await apis.coldStorage.getDetail({ id: props.id });
        const { province = '', city = '', area = '' } = res;
        res.newsType = String(res.newsType);
        data.form = res;
        data.form.provinceCityArea = [province, city, area];
        if (data.form.briefText) {
          data.quillEditorRef.setHTML(data.form.briefText);
        }
        if (data.form.handBook) {
          data.fileList.push(
            {
              name: data.form.handBook,
              url: data.form.handBook,
            },
          );
        }
      } finally {
        data.loading = false;
      }
    }

    const updatePosi = (val = false) => {
      data.temp = val;
    };

    let autoComplete;
    function initMap() {
      AMapLoader.load({
        key: '84e663f0b6856f89a8824ad0e7e8b865',
        version: '2.0',
        plugins: ['AMap.AutoComplete'],
      }).then((AMap) => {
        const autoOptions = {
          city: '全国',
        };
        autoComplete = new AMap.Autocomplete(autoOptions);
      }).catch(() => {});
    }

    function remoteMethod(val) {
      autoComplete.search(val, (status, result) => {
        if (Object.keys(result).length) {
          data.options = result.tips;
        }
      });
    }

    onMounted(() => {
      if (props.id) {
        loadDetail();
      } else {
        props.id || data.formRef?.unlock();
      }
      initMap();
    });

    function handleChange(val) {
      data.form.province = val[0] || '';
      data.form.city = val[1] || '';
      data.form.area = val[2] || '';
      remoteMethod(val.join(''));
    }

    function handleSelect(val) {
      const find = data.options.find(item => item.name === val);
      if (find) {
        const { lat: coordinateX, lng: coordinateY } = find.location || {};
        data.form.coordinateX = coordinateX;
        data.form.coordinateY = coordinateY;
      }
    }

    const updateUrl = (val) => {
      data.form.titlePic = val;
    };

    const updateUrl2 = (val) => {
      data.form.linkedPic = val;
    };

    const updateUrl3 = (val) => {
      data.form.linkedVideo = val;
    };

    const deleteUrl = () => {
      data.form.linkedVideo = '';
    };

    const blurSelect = (e) => {
      if (!e.target.value) return;
      nextTick(() => {
        data.form.address = e.target.value;
      });
    };

    const beforeUpload = (file) => {
      const { size, name } = file;
      const typeImg = [
        'jpg',
        'png',
        'png',
        'gif',
        'pdf',
        'xlsx',
        'xls',
        'docx',
      ];
      const type = name.split('.')[name.split('.').length - 1];
      if (!typeImg.includes(type)) {
        showErrorMsg('格式支持.png, .jpg, .gif .pdf.xlsx .xls .docx');
        return false;
      }
      if (size > 1024 * 1024 * 10) {
        showErrorMsg('大小不能超过10M');
        return false;
      }
      return true;
    };

    const uploadFile = async (val) => {
      const { file } = val;
      try {
        const fd = new FormData();
        fd.append('file', file);
        const res = await apis.upload.uploadFile(fd);
        data.fileList.push({
          url: res.filePath,
          name: res.fileName,
        });
        const url = data.fileList.map(item => item.url).join(',');
        data.form.handBook = url;
      } catch (err) {

      } finally {
      }
    };

    function handleRemove() {
      data.fileList = [];
      data.form.handBook = '';
    }

    return {
      save,
      ...toRefs(data),
      updatePosi,
      map,
      handleChange,
      remoteMethod,
      handleSelect,
      updateUrl,
      updateUrl2,
      deleteUrl,
      updateUrl3,
      blurSelect,
      beforeUpload,
      uploadFile,
      handleRemove,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep.active-img-list .el-upload {
  display: none;
}
::v-deep .el-upload-list {
  max-width: 800px;
}
::v-deep .quill-editor {
  height: 400px;
}
::v-deep .ql-container{
  height: 300px;
}
.posi {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20px;
  z-index: 999;
  background-color: #f5f7fa;
  opacity: 0.5;
  cursor:not-allowed;
}
::v-deep .el-cascader .el-input,
.el-input {
  --el-input-focus-border: #2F51FF;
}
.el-cascader-node.in-active-path{
  color: #2F51FF !important;
}
.int-box-posi {
  position: relative;
}
.lis-box {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  height: 100px;
  border: 1px solid red;
}
.el-select {
  width: 100%;
}
::v-deep.el-select .el-input.is-focus .el-input__inner {
  border-color: #2F51FF;
}
::v-deep.el-select .el-input__inner:focus {
  border-color: #2F51FF;
}
</style>
